<template>
  <div class="objects">
    <Loader style="height: 223px;" v-if="!visCount"/>
    <div v-else>
    <!--<h1 style="width: 100%;" class="mb-3">Статистика</h1> -->
    <div class="flex items-center">
      <div class="relative">
        <DropDownButton :items="periodList" :period="selectedPeriod" @select="onChangePeriod">
        </DropDownButton>
        <r-date-picker
          label=""
          class="cleaning_dateResurs"
          fill="rocky"
          style="margin-left: -200px;"
          v-model="period"
          @input="perodFnc"
          :period="true"
        ></r-date-picker>
      </div>
    </div>
    <r-tabs class="mt-7" :items="tabItems" v-model="activeTabId" @change="clickTab()" />
    <div v-if="activeTabId.id == 'objects'">
      <div>
        <div class="point mt-8 mb-6 mr-8">
          <div class="point-number">
            {{Number(total)}}
          </div>
          <div class="point-text">
            обращений поступило
          </div>
        </div>
        <div class="point mt-8 mb-6 mr-8">
          <div class="point-number">
            {{ Number(inWork) + Number(removed) + Number(closed)}}
          </div>
          <div class="point-text">
            взято в работу
          </div>
        </div>
        <div class="point mt-8 mb-6 mr-8">
          <div class="point-number">
            {{removed}}
          </div>
          <div class="point-text">
            не прошло модерацию
          </div>
        </div>
        <div class="point mt-8 mb-6 mr-8">
          <div class="point-number">
            {{closed}}
          </div>
          <div class="point-text">
            выполнено
          </div>
        </div>
        <div class="point mt-8 mb-6 mr-8">
          <div class="point-number">
            {{inWork}}
          </div>
          <div class="point-text">
            сейчас в работе
          </div>
        </div>
      </div>
      <Schedule :key="keyGraf" :dateEnd="dateEnd" :date="date" :items="resSchedule" v-if="ScheduleItem"/>
      <ScheduleAdmin :key="'admin' + keyGraf" :dateEnd="dateEnd" :date="date" :items="solvedTimeline" v-if="ScheduleAdminItem" />
      <ScheduleWarm :items="typesName" v-if="ScheduleWarmItem"/>
      <ScheduleAnswer :key="'answer' + keyGraf" :dateEnd="dateEnd" :date="date" :items="officialTimeline" :itemsToo="workerEmails" v-if="ScheduleAnswerItem"/>
      <ScheduleAccuracy :key="'acc' + keyGraf" :items="duraTwo" v-if="ScheduleAccuracyItem" />
      <ScheduleTime :key="'time' + keyGraf" :items="dura" v-if="ScheduleTimeItem"/>
      <ScheduleCategories :key="'categories' + keyGraf" :items-name="typesName" :items="types" v-if="ScheduleCategoriesItem" />
      <ScheduleWork :key="'work' + keyGraf" :items="ratings" v-if="ScheduleWorkItem" />
      <ScheduleUser :key="'user' + keyGraf" :dateEnd="dateEnd" :date="date" :items="users" v-if="ScheduleUserItem"/>
      <ScheduleSource :key="'source' + keyGraf" :items="source" v-if="ScheduleSourceItem"/>
      <ScheduleAmountUser :key="'amount' + keyGraf" :items="byAmount" v-if="ScheduleAmountUserItem" />
      <ScheduleMobile :key="'mobile' + keyGraf" :items="devices" v-if="ScheduleMobileItem"/>
      <ScheduleRegistration :key="'reg' + keyGraf" :items="logins" v-if="ScheduleRegistrationItem" />
      <ScheduleReaction :key="'reaction' + keyGraf" :dateEnd="dateEnd" :date="date" :items="likes" v-if="ScheduleReactionItem" />
      <ScheduleComment  :key="'сomment' + keyGraf" :dateEnd="dateEnd" :date="date" :items="сomment" v-if="ScheduleCommentItem" />
      <ScheduleSite  :key="'sites' + keyGraf" :dateEnd="dateEndViews" :date="dateViews" :items="statMetrika" v-if="ScheduleSiteItem" />
      <ScheduleInterval :key="'interval' + keyGraf" :dateEnd="dateEnd" :date="date" :items="duraM" v-if="delegate" />
      <ScheduleLong :key="'long' + keyGraf" :dateEnd="dateEnd" :date="date" :items="duraMM" v-if="delegate"/>
      <ScheduleNotClose :key="'close' + keyGraf" :dateEnd="dateEnd" :date="date" :items="duraO" v-if="delegate"/>
      <SchedulePlus :key="'plus' + keyGraf" :dateEnd="dateEnd" :date="date" :items="reviews" v-if="delegate"/>
      <UsersTimeline :key="'users' +keyGraf" :dateEnd="dateEnd" :date="date" :items="usersTimeline" v-if="usersTimelineBoolean"/>
      <ScheduleViews  :key="'site' + keyGraf" :dateEnd="dateEndViews" :date="dateViews" :items="ScheduleViews" v-if="ScheduleViewsBoolean"/>
      <div class="pt-8 w-full flex" style="width: 100%;" v-if="city">
        <r-button-action
          @click="yandexLink()"
          title="Статистика Yandex.Метрика"
          icon="link"
          color="rocky"
          size="sulguni"
        />
      </div>
    </div>
     <div v-if="activeTabId.id == 'organisation'">
       <Bus :key="'admin' + keyGraf" :dateEnd="dateEnd" :date="dateViews" :items="stopsTimeline" />
       <Days :key="'days' + keyGraf" :dateEnd="dateEnd" :date="date" :items="stopsTimelineDay"  />
       <Warm v-if="false" :key="'days' + keyGraf" :dateEnd="dateEnd" :date="date" :items="busRating" />
       <Complaints :key="'component' + keyGraf" :dateEnd="dateEnd" :date="date" :items="component" />
       <Routes v-if="false" :key="'component' + keyGraf" :dateEnd="dateEnd" :date="date" :items="busRatingStar" />
     </div>
    </div>
  </div>
</template>

<script>
import IconGraf from "@/components/IconGraf";
import ScheduleSite from "@/components/Appeals/ScheduleSite";
import ScheduleComment from "@/components/Appeals/ScheduleComment";
import ScheduleReaction from "@/components/Appeals/ScheduleReaction";
import ScheduleRegistration from "@/components/Appeals/ScheduleRegistration";
import ScheduleAmountUser from "@/components/Appeals/ScheduleAmountUser";
import ScheduleMobile from "@/components/Appeals/ScheduleMobile";
import ScheduleSource from "@/components/Appeals/ScheduleSource";
import ScheduleUser from "@/components/Appeals/ScheduleUser";
import ScheduleWork from "@/components/Appeals/ScheduleWork";
import ScheduleCategories from "@/components/Appeals/ScheduleCategories";
import ScheduleAccuracy from "@/components/Appeals/ScheduleAccuracy";
import DropDownButton from "@/components/DropDownButton";
import AdditionalMenu from "@/components/AdditionalMenu";
import Schedule from "@/components/Appeals/Schedule";
import ScheduleAdmin from "@/components/Appeals/ScheduleAdmin";
import ScheduleWarm from "@/components/Appeals/ScheduleWarm";
import ScheduleAnswer from "@/components/Appeals/ScheduleAnswer";
import ScheduleTime from "@/components/Appeals/ScheduleTime";
import ScheduleLong from "@/components/Appeals/ScheduleLong";
import ScheduleInterval from "@/components/Appeals/ScheduleInterval";
import ScheduleNotClose from "@/components/Appeals/ScheduleNotClose";
import SchedulePlus from "@/components/Appeals/SchedulePlus";
import Bus from "@/components/Transport/Bus";
import Days from "@/components/Transport/Days";
import Warm from "@/components/Transport/Warm";
import Complaints from "@/components/Transport/Complaints";
import Routes from "@/components/Transport/Routes";
import UsersTimeline from "@/components/Appeals/UsersTimeline.vue";
import ScheduleViews from "@/components/Appeals/ScheduleViews.vue";
import PlaceApi from '@/api/PlaceApi';
import Loader from '@/components/Loader.vue';

export default {
  name: 'object-list',
  components: {
    Loader,
    DropDownButton,
    AdditionalMenu,
    IconGraf,
    Schedule,
    ScheduleAdmin,
    ScheduleWarm,
    ScheduleAnswer,
    ScheduleTime,
    ScheduleCategories,
    ScheduleAccuracy,
    ScheduleWork,
    ScheduleUser,
    ScheduleSource,
    ScheduleMobile,
    ScheduleAmountUser,
    ScheduleRegistration,
    ScheduleReaction,
    ScheduleComment,
    ScheduleSite,
    ScheduleInterval,
    ScheduleLong,
    ScheduleNotClose,
    SchedulePlus,
    Bus,
    Days,
    Warm,
    Complaints,
    Routes,
    UsersTimeline,
    ScheduleViews
  },
  data() {
    return {
      user: 0,
      resSchedule: {},
      solvedTimeline: {},
      officialTimeline: {},
      workerEmails: {},
      dura: {},
      types: {},
      typesName: {},
      duraTwo: {},
      ratings: {},
      users: {},
      source: {},
      devices: {},
      byAmount: {},
      logins: {},
      likes: {},
      сomment: {},
      statMetrika: {},
      stops: {},
      component: {},
      duraM: {},
      duraMM: {},
      duraO: {},
      dateBus: null,
      reviews: {},
      usersTimeline: {},
      ScheduleViews: {},
      keyGraf: 0,
      activeTabId: {
        id: 'objects',
        title: 'Обращения',
        count: ''
      },
      period: null,
      date: null,
      dateEnd: null,
      dateViews: null,
      dateEndViews: null,
      selectedPeriod: 'За последний год',
      selectedPeriodId: 'thisYear',
      periodList: [
        {id: 'all', active: false, value: 'За все время'},
        {id: 'thisYear', active: true, value: 'За последний год'},
        {id: 'thisSixMonth', active: false, value: 'За последние полгода'},
        {id: 'thisTreeMonth', active: false, value: 'За последние 3 месяца'},
        {id: 'thisMonth', active: false, value: 'За последний месяц'},
        {id: 'thisPeriod', active: false, value: 'Выбрать промежуток'}
      ],
      total: 0, //поступило обращений
      assigned: 0,
      removed: 0,
      closed: 0,
      inWork: 0,
      visCount: false,
      coors: [],
      ScheduleItem: false,
      ScheduleAdminItem: false,
      ScheduleWarmItem: false,
      ScheduleAnswerItem: false,
      ScheduleTimeItem: false,
      ScheduleCategoriesItem: false,
      ScheduleAccuracyItem: false,
      ScheduleWorkItem: false,
      ScheduleUserItem: false,
      ScheduleSourceItem: false,
      ScheduleMobileItem: false,
      ScheduleAmountUserItem: false,
      ScheduleRegistrationItem: false,
      ScheduleReactionItem: false,
      ScheduleCommentItem: false,
      ScheduleSiteItem: false,
      ComplaintsItem: false,
      duraMMItem: false,
      duraMItem: false,
      duraOItem: false,
      reviewsItem: false,
      stopsTimelineDayBoolean: false,
      stopsTimelineDay: {},
      usersTimelineBoolean: false,
      ScheduleViewsBoolean: false,
      stopsTimelineBoolean: false,
      stopsTimeline: {},
      resActive: null,
      resCoors: null,
      resType: null,
      boolMounted: true,
      busRatingBoolean: false,
      busRating: {},
      busRatingStarBoolean: true,
      busRatingStar: {},
      isPublicable: null,
      delegate: false,
      userRoot: false,
      city: null
    }
  },
  computed: {
    tabItems() {
      const tabs = [
        {
          id: 'objects',
          title: 'Обращения',
          count: ''
        },
        {
          id: 'organisation',
          title: 'Транспорт',
          count: ''
        }
      ];
      return tabs;
    },
  },
  async mounted() {
    const api = new PlaceApi();
    const response = await api.systemOptions();
    this.isPublicable = response?.designOptions?.showExtendedStats;
    var d = new Date();
    this.date = new Date(d.setMonth(d.getMonth() - 11));
    this.dateEnd = new Date(
      new Date().setMonth(new Date().getMonth() + 2));
    if(this.boolMounted) {
      const city = await api.systemCity();
      if(city.yandexMetrika) {
        this.city = city.yandexMetrika;
      }
      this.user = await api.getUser();
      this.user = this.user?.access?.role;
      this.userRoot = this.user?.user?.root;
      if(this.isPublicable) {
        this.delegate = (this.isPublicable == 'all' ? true :
        (this.isPublicable == 'admin'
          && (this.user != "USER" || this.userRoot == true) ? true :
          (this.isPublicable == 'auth' && this.user ? true : false)
        ));
      }
      this.resType = await api.getTypes();
      this.allDatesTypes();
      if (this.date != null) {
        this.resActive = await api.getActive(this.date.toLocaleDateString());
        this.allDates();
      } else {
        this.resActive = await api.getActive();
        this.allDates();
      }
      this.resCoors = await api.getCoors();
      this.allDatesCoors();
      this.boolMounted = false;
    }
  },
  methods: {
    yandexLink() {
      window.open(`https://metrika.yandex.ru/dashboard?group=day&period=week&id=${this.city}`, '_blank');
    },
    infoDate(item){
      item.sort(function(a,b){
        if (a < b) return -1;
        if (a > b) return  1;

        return 0;
      })
    },
    async perodFnc(e) {
      const api = new PlaceApi();
      if (this.selectedPeriodId == 'thisPeriod') {
        this.selectedPeriod = this.period[0].replace(/[-]/g, '.') + '-' + this.period[1].replace(/[-]/g, '.');
        this.date = new Date(
          new Date(this.period[0].replace(/[-]/g, '.'))
            .setMonth(
              new Date(this.period[0].replace(/[-]/g, '.')
              ).getMonth()));

        this.dateEnd = new Date(
          new Date(this.period[1].replace(/[-]/g, '.'))
            .setMonth(
              new Date(this.period[1].replace(/[-]/g, '.')
              ).getMonth() + 2));
        this.dateViews = new Date(this.period[0].replace(/[-]/g, '.'));
        this.dateEndViews = new Date(this.period[1].replace(/[-]/g, '.'));
        console.log(555555,this.period[1], this.period[0])
        if(this.period[1] && this.period[0]) {
          this.resActive = await api.getActive(new Date(this.period[0].replace(/[-]/g, '.')).toLocaleDateString(), new Date(this.period[1].replace(/[-]/g, '.')).toLocaleDateString());
          this.allDates();
          this.allDatesCoors();
          this.allDatesTypes();
        }
      }
    },
    async onChangePeriod(e) {
      const api = new PlaceApi();
      this.dateBus = null;
      this.periodList = this.periodList.map(el => ({...el, active: el.value === e.value}));
      this.selectedPeriod = e.value;
      this.selectedPeriodId = e.id;
      var d = new Date();
      this.dateEnd = new Date(
        new Date().setMonth(new Date().getMonth() + 2));
      if (e.id == 'thisYear') {
        this.date = new Date(d.setMonth(d.getMonth() - 11));
        this.dateViews = new Date(d.setMonth(d.getMonth()-11));
      }
      if (e.id == 'thisSixMonth') {
        this.date = new Date(d.setMonth(d.getMonth() - 5));
        this.dateViews = new Date(d.setMonth(d.getMonth()-5));
      }
      if (e.id == 'thisTreeMonth') {
        this.date = new Date(d.setMonth(d.getMonth() - 2));

        this.dateViews = new Date(d.setMonth(d.getMonth() - 2));
      }
      if (e.id == 'thisMonth') {
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        this.date = new Date(y, m, 1);
        this.dateViews = new Date(d.setMonth(d.getMonth()));
        this.resActive = await api.getActive(this.date.toLocaleDateString(), new Date().toLocaleDateString());
      }
      if (e.id == 'all') {
        this.date = null;
        this.dateViews = null;
        this.resActive = await api.getActive(0, new Date().toLocaleDateString());
      }
      if (e.id != 'all' && e.id != 'thisMonth') {
        this.resActive = await api.getActive(this.date.toLocaleDateString(), new Date().toLocaleDateString());
      }
      if(e.id != 'thisPeriod') {
        this.allDates();
        this.allDatesCoors();
        this.allDatesTypes();
      }
      if (e.id == 'thisPeriod') {
        var parent = document.querySelectorAll('.cleaning_dateResurs')[0];
        parent.querySelectorAll('.RDatePicker__input')[0].click();
      }
    },
    allDatesCoors() {
      let resCoors = this.resCoors;
      if(resCoors?.statistics?.coords) {
        this.coors = resCoors.statistics.coords;
        this.ScheduleWarmItem = true;
      }
    },
    allDatesTypes() {
      let resType = this.resType;
      if (resType) {
        this.typesName = resType;
      }
    },
    allDates() {
      let _this = this;
      let res = null;
      res = _this.resActive;

      this.ScheduleItem = false;
      this.usersTimelineBoolean = false;
      this.ScheduleViewsBoolean = false;
      this.ScheduleAdminItem = false;
      this.ScheduleAnswerItem = false;
      this.ScheduleAnswerItem = false;
      this.ScheduleUserItem = false;
      this.ScheduleTimeItem = false;
      this.ScheduleWorkItem = false;
      this.ScheduleSourceItem = false;
      this.ScheduleAmountUserItem = false;
      this.ScheduleRegistrationItem = false;
      this.ScheduleReactionItem = false;
      this.ScheduleMobileItem = false;
      this.ScheduleCommentItem = false;
      this.ScheduleSiteItem = false;
      this.ScheduleAccuracyItem = false;
      this.ComplaintsItem = false;
      this.ScheduleCategoriesItem = false;
      this.duraMItem = false;
      this.duraMMItem = false;
      this.duraOItem = false;
      this.reviewsItem = false;
      this.stopsTimelineBoolean = false;
      this.stopsTimelineDayBoolean = false;
      this.busRatingBoolean = false;
      this.busRatingStarBoolean = false;

      //for (var i in res.statistics.solvedTimeline) {
       // _this.coorsEtastops.push(["" + i + " " + _this.declOfNum(i, ['сообщение','сообщения','сообщений']),+_this.items[i]]);
      //}
      if(res?.statistics?.newtaskTimeline) {
        this.resSchedule = res.statistics.newtaskTimeline;
        this.ScheduleItem = true;
      }
      if(res?.statistics?.usersTimeline) {
        this.usersTimeline = res.statistics.usersTimeline;
        this.usersTimelineBoolean = true;
      }
      if(res?.statistics?.statMetrika?.users) {
        this.ScheduleViews = res.statistics.statMetrika.users;
        let metrika = [];
        this.ScheduleViews.map(el => {
          if(metrika[_this.dateFormat(el.created)]) {
            metrika[_this.dateFormat(el.created)].users += Number(el.users);
          } else {
            metrika[_this.dateFormat(el.created)] = { created: null, users: null }
            metrika[_this.dateFormat(el.created)].created = this.dateFormat(el.created);
            metrika[_this.dateFormat(el.created)].users = Number(el.users);
          }
        });

        this.ScheduleViews = metrika;

        _this.ScheduleViews.sort(function(a,b){
          if (a.created < b.created){
            return -1;
          }else if (a.created > b.created) {
            return  1;
          }else{
            return 0;
          }
        })
        this.ScheduleViewsBoolean = true;
      }

      if(res?.statistics?.solvedTimeline) {
        _this.solvedTimeline = res.statistics.solvedTimeline;
        this.ScheduleAdminItem = true;
      }
      if(res?.statistics?.officialTimeline) {
        this.officialTimeline = res.statistics.officialTimeline;
        this.ScheduleAnswerItem = true;
      }
      if(res?.statistics?.workerEmails) {
        this.workerEmails = res.statistics.workerEmails;
        this.ScheduleAnswerItem = true;
      }
      if(res?.statistics?.usersTimeline) {
        this.users = res.statistics.usersTimeline;
        this.ScheduleUserItem = true;
      }
      if(res?.statistics?.dura) {
        this.dura = res.statistics.dura;
        this.ScheduleTimeItem = true;
      }
      if(res?.statistics?.ratings) {
        this.ratings = res.statistics.ratings;
        this.ScheduleWorkItem = true;
      }
      if(res?.statistics?.taskPlatforms) {
        this.source = res.statistics.taskPlatforms;
        this.ScheduleSourceItem = true;
      }
      if(res?.statistics?.core?.byAmount) {
        this.byAmount = res.statistics.core.byAmount;
        this.ScheduleAmountUserItem = true;
      }
      if(res?.statistics?.logins) {
        this.logins = res.statistics.logins;
        this.ScheduleRegistrationItem = true;
      }
      if(res?.statistics?.likes) {
        this.likes = res.statistics.likes;
        this.ScheduleReactionItem = true;
      }
      if(res?.statistics?.devices) {
        this.devices = res.statistics.devices;
        this.ScheduleMobileItem = true;
      }
      if(res?.statistics?.commentsTimeline) {
        this.сomment = res.statistics.commentsTimeline;
        this.ScheduleCommentItem = true;
      }

      if(res?.statistics?.solvedTimeline) {
       // this.stops = res.statistics.solvedTimeline;
      }
      if(res?.statistics?.statMetrika?.views) {
        this.statMetrika = res.statistics.statMetrika.views;
        let metrika = [];
        this.statMetrika.map(el => {
          if(metrika[_this.dateFormat(el.created)]) {
            metrika[_this.dateFormat(el.created)].views += Number(el.views);
          } else {
            metrika[_this.dateFormat(el.created)] = { created: null, views: null }
            metrika[_this.dateFormat(el.created)].created = this.dateFormat(el.created);
            metrika[_this.dateFormat(el.created)].views = Number(el.views);
          }
        });

        this.statMetrika = metrika;
        this.statMetrika.sort(function(a,b){
          if (a.created < b.created){
            return -1;
          }else if (a.created > b.created) {
            return  1;
          }else{
            return 0;
          }
        })

        this.ScheduleSiteItem = true;
      }
      if(res?.statistics?.typesTwo) {
        this.duraTwo = res.statistics.typesTwo;
        this.ScheduleAccuracyItem = true;
      }
      if(res?.statistics?.busRatingTimeline) {
        this.component = res.statistics.busRatingTimeline;
        this.ComplaintsItem = true;
      }
      if(res?.statistics?.types) {
        this.types = res.statistics.types;
        this.ScheduleCategoriesItem = true;
      }

      if(res?.statistics?.duraM) {
        this.duraM = res.statistics.duraM;
        this.duraMItem = true;
      }
      if(res?.statistics?.duraMM) {
        this.duraMM = res.statistics.duraMM;
        this.duraMMItem = true;
      }
      if(res?.statistics?.duraO) {
        this.duraO = res.statistics.duraO;
        this.duraOItem = true;
      }
      if(res?.statistics?.reviews) {
        this.reviews = res.statistics.reviews;
        this.reviewsItem = true;
      }

      if(res?.statistics?.total) {
        this.total = res.statistics.total;
      }
      if(res?.statistics?.assigned) {
        this.assigned = res.statistics.assigned;
      }
      if(res?.statistics?.removed) {
        this.removed = res.statistics.removed;
      }
      if(res?.statistics?.closed) {
        this.closed = res.statistics.closed;
      }
      if(res?.statistics?.inWork) {
        this.inWork = res.statistics.inWork;
      }
      if(res?.statistics?.stopsTimeline) {
        for (var i in res.statistics.stopsTimeline) {
          _this.stopsTimeline[res.statistics.stopsTimeline[i][2]] = { 'd': res.statistics.stopsTimeline[i][1], 'c': res.statistics.stopsTimeline[i][0], 'c2': res.statistics.stopsTimeline[i][2] };
        }
        _this.stopsTimelineBoolean = true;
      }
      if(res?.statistics?.stopsTimelineDay) {
        for (var i in res.statistics.stopsTimelineDay) {
          _this.stopsTimelineDay[i+ ':00'] = { 'd': i + ':00', 'c': res.statistics.stopsTimelineDay[i][1] ,'c2': res.statistics.stopsTimelineDay[i][0] };
        }
        _this.stopsTimelineDayBoolean = true;
      }
      if(res?.statistics?.busRating?.refs) {
        for (var i in res.statistics.busRating.refs) {
          this.busRating[i] = { 'd': i, 'c': res.statistics.busRating.refs[i]};
        }

        this.busRatingBoolean = true;
      }
      if(res?.statistics?.busRating) {
        for (var i in res.statistics.busRating) {
          if(i == 'rating1') {
            this.busRatingStar['Комфорт поездки'] = res.statistics.busRating[i];
          }
          if(i == 'rating2') {
            this.busRatingStar['Свободные места'] = res.statistics.busRating[i];
          }
          if(i == 'rating3') {
            this.busRatingStar['Вовремя пришёл'] = res.statistics.busRating[i];
          }
          if(i == 'rating4') {
            this.busRatingStar['Быстро доехал'] = res.statistics.busRating[i];
          }
        }
        this.busRatingStarBoolean = true;
      }
      this.visCount = true;
      this.keyGraf++;
    },
    dateFormat(item){
      const date = new Date(item);
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = `0${mm}`;
      const yy = date.getFullYear();

      return yy + '-' + mm;
    },
    clickTab() {
      this.keyGraf++;
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.cleaning_date {
  ::v-deep .rir-input {
    margin-left: 180px;
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.point {
  display: table;
  width: 131px;
  float: left;
  height: 70px;
}
.point-number {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 36px;
  color: #81ABEE;
}
.point-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.72;
}

.cleaning_dateResurs {
  visibility: hidden;
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
