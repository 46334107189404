var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-popover',{attrs:{"disabled":_vm.readonly,"content-class":"rir-select__content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('r-input',{staticClass:"rir-select",class:{
        disabled: _vm.readonly
      },attrs:{"readonly":"","error":_vm.error,"max-width":_vm.maxWidth,"placeholder":_vm.placeholder,"label":_vm.label,"value":_vm.model},nativeOn:{"click":function($event){return on.click.apply(null, arguments)}}},[_c('a',{ref:"icon",staticClass:"rir-select__icon",attrs:{"slot":"after","tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.changeViewList.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.changeViewList.apply(null, arguments)}},slot:"after"},[_c('r-icon',{class:{
            active: _vm.showSelect
          },attrs:{"size":20,"fill":_vm.error ? 'prestige' : 'rocky',"icon":"arrow-down"}})],1)])]}}]),model:{value:(_vm.showSelect),callback:function ($$v) {_vm.showSelect=$$v},expression:"showSelect"}},[(_vm.itemsList.length)?_c('r-list',{attrs:{"overflow":""}},_vm._l((_vm.itemsList),function(item){return _c('r-list-item',{key:item.forUuId,class:{
        active: item[_vm.idValue] === _vm.modelId,
        disabled: item.disabled
      },nativeOn:{"click":function($event){!item.disabled && _vm.selectItem(item)}}},[(!_vm.isSlotItem)?[(item.icon)?_c('img',{staticClass:"mr-2 img_svg",style:('background-color: #3D75E4;-webkit-mask: url(https://storage.rosatom.city/platform-prod/volgodonsk/common/icons/16/'+item.icon+'.svg) no-repeat center;mask: url(https://storage.rosatom.city/platform-prod/volgodonsk/common/icons/16/'+item.icon+'.svg) no-repeat center;    ' +
         'width: 16px;'+
         'height: 16px')}):_vm._e(),_vm._v(" "+_vm._s(item[_vm.textValue])+" ")]:_vm._e(),_vm._t("item",null,{"item":item})],2)}),1):_c('div',{staticClass:"rir-select__no-date"},[_vm._v(_vm._s(_vm.noDataItems))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }