<template>
  <div class="accordion-clamp" >
    <div class="accordion-clamp__content" :style="textStyle" ref="container">
      <!-- В default слот помещается элемент или текст, который будет обрезаться -->
      <slot></slot>
      <!-- В слот after помещается элемент, который необходимо показать после текста -->
    </div>
    <button
      type="button"
      v-show="isOverFlow"
      class="accordion-clamp__btn inline-block sulguni mt-2 ml-4"
      @click="checkOpen()"
    >
      {{ isOpen ? 'Свернуть' : 'Развернуть' }}<r-icon
        :class="['accordion-clamp__btn-icon', { 'accordion-clamp__btn-icon--opened': isOpen }]"
        icon="arrow-up"
        :size="8"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'TextAccordion',
  props: {
    lines: {
      type: [String, Number],
      default: 5
    },
    count: {
      type: [String, Number],
      default: 5
    }
  },
  data: () => ({
    isOpen: null,
    isOverFlow: true,
    isMounted: false,
    clampElement: null
  }),
  mounted() {
    if (this.count <= 5) {
      this.isOverFlow = false;
    }
  },
  methods: {
    editArr() {
      this.indexArr = !this.indexArr;
    },
    checkOpen() {
      this.isOpen = !this.isOpen
      this.$emit('change');
    }
  },
  computed: {
    textStyle() {
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-clamp {
  width: 220px;
  display: table;
  &__btn {
    color: var(--rir-rocky);
    cursor: pointer;
    background: none;
  }
  &__btn-icon {
    margin-left: 8px;
    transition: 0.2s all;
    transform: translateY(-1px) rotate(180deg);
  }
  &__btn-icon--opened {
    transform: translateY(-1px) rotate(0deg);
  }
  &__content {
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden;
  }
}
</style>
