<template>
  <r-popover
    :disabled="readonly"
    content-class="rir-select__content"
    v-model="showSelect"
  >
    <template v-slot:activator="{ on }">
      <r-input
        readonly
        :error="error"
        :max-width="maxWidth"
        :placeholder="placeholder"
        :label="label"
        :value="model"
        @click.native="on.click"
        class="rir-select"
        :class="{
          disabled: readonly
        }"
      >
        <a
          class="rir-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
          @keyup.tab="changeViewList"
          @keydown.tab="changeViewList"
        >
          <r-icon
            :size="20"
            :class="{
              active: showSelect
            }"
            :fill="error ? 'prestige' : 'rocky'"
            icon="arrow-down"
          />
        </a>
      </r-input>
    </template>
    <r-list overflow v-if="itemsList.length">
      <r-list-item
        @click.native="!item.disabled && selectItem(item)"
        v-for="item in itemsList"
        :class="{
          active: item[idValue] === modelId,
          disabled: item.disabled
        }"
        :key="item.forUuId"
      >
        <template v-if="!isSlotItem">
          <img v-if="item.icon" :style="'background-color: #3D75E4;-webkit-mask: url(https://storage.rosatom.city/platform-prod/volgodonsk/common/icons/16/'+item.icon+'.svg) no-repeat center;mask: url(https://storage.rosatom.city/platform-prod/volgodonsk/common/icons/16/'+item.icon+'.svg) no-repeat center;    ' +
           'width: 16px;'+
           'height: 16px'" class="mr-2 img_svg">
          {{ item[textValue] }}
        </template>
        <slot name="item" :item="item" />
      </r-list-item>
    </r-list>
    <div class="rir-select__no-date" v-else>{{ noDataItems }}</div>
  </r-popover>
</template>

<script>

export default {
  name: 'rir-select',
  mounted() {
    if (this.selectFirstItem) {
      this.item = this.items[0];
    }
    this.item =
      this.itemsList.find(
        el =>
          el[this.idValue] ===
          (this.returnObject ? this.value[this.idValue] : this.value)
      ) || null;
  },
  components: {},
  data: () => ({
    showSelect: false,
    item: null
  }),
  props: {
    value: {
      validator: prop =>
        ['string', 'object', 'number'].includes(typeof prop) || prop === null,
      default: () => ({})
    },
    returnObject: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    items: {
      type: Array,
      default: () => []
    },
    textValue: {
      type: String,
      default: 'value'
    },
    idValue: {
      type: String,
      default: 'id'
    },
    noDataItems: {
      type: String,
      default: 'Список пустой'
    },
    selectAll: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    selectFirstItem: {
      type: Boolean
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function(val) {
      this.item = this.itemsList.find(
        el => el[this.idValue] === (this.returnObject ? val[this.idValue] : val)
      );
    }
  },
  computed: {
    model() {
      return this.item ? this.item[this.textValue] : null;
    },
    modelId() {
      return this.item ? this.item[this.idValue] : null;
    },
    itemsList() {
      const items = this.items.map(el => ({
        ...el,
        forUuId: this.uuIdv4()
      }));
      if (this.selectAll) {
        items.unshift({
          [this.idValue]: null,
          [this.textValue]: 'Все',
          forUuId: this.uuIdv4()
        });
      }
      return items;
    },
    isSlotItem() {
      return !!this.$slots.item || !!this.$scopedSlots.item;
    }
  },
  methods: {
    changeViewList() {
      this.showSelect = !this.readonly && !this.showSelect;
    },
    selectItem(item) {
      this.item = { ...item };
      delete item.forUuId;
      const val = this.returnObject ? item : item[this.idValue];
      this.$emit('change', item);
      this.$emit('input', val);
      this.showSelect = false;
    }
  }
};
</script>
<style scoped>
.img_svg:hover { fill: #303 !important; }
</style>
