import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getActive(from = 0,to ) {
    let fromDate = '';
    if(from) {
      fromDate= `&to=${to}&from=${from}`
    }
    const { data } = await this._axios.get(`/ajax.php?action=getAll&v=2&stat=1&wide=1&masterId=0${fromDate}`);

    return data;
  }
  async getCoors(item) {
    let types = '';
    if(item != null){
      types = `&types=${item}`;
    }
    const { data } = await this._axios.get(`/ajax.php?action=getAll&stat=1&wide=1&heatmap=1&statHeatMapSwitch1=1&statHeatMapSwitch2=1${types}`);

    return data;
  }
  async getTypes() {
    const { data } = await this._axios.get(`/ajax.php?action=getAllProblemTypes&asArray=1`);

    return data;
  }
  async getUser() {
    const { data } = await this._axios.get('/auth/rest/access/STATS', {
    });

    return data;
  }

  async systemCity() {
    const { data } = await this._axios.get('/nginxApi.php?get=city', {
    });

    return data;
  }
  async systemOptions() {
    const { data } = await this._axios.get('/ajax.php?action=loadSystemOptions', {
    });

    return data;
  }
}
