<template>
  <div>
    <div class="w-full time">
      <div class="m-8px p-16px bg-white radius-16 border-arrival" style="display: flow-root;">
        <div class="flex justify-between">
          <div class="flex items-center">
            <div class="overflow-ellipsis webkit-box text-h20-24 color-titanic">
              Оценка работы по обращению
            </div>
          </div>
        </div>
        <div class="rir-drag-upload-file__uploading" style="height: 277px;" v-if="uploadGraph">
          <Loader style="height: 223px;"/>
        </div>
        <div v-if="!uploadGraph">
          <div v-if="series && series.length > 0">
            <div class="mt-20px graf">
              <apexchart
                class="isSingleItem"
                style="margin-top: -20px;"
                :type="'pie'"
                :options="chartOptions"
                :series="series"
                height="220"></apexchart>
              </div>
            <div class="legend mt-6"  v-if="count">
              <TextAccordion  @change="editArr()" :count="arr.length">
                <template #default>
                  <div v-show="indexArr">
                    <div v-for="(item, index) in arr" v-if="index < 5">
                      <div class="ml-12px radius-100 flex-none mr-3 mt-1 mb-3"
                           :style="'width: 8px; height: 8px; background-color: ' + colors[index] + ';float: left;'">
                      </div>
                      <div class="opacity-72 ml-6px whitespace-nowrap text-c13 color-titanic mb-3">
                        {{item[0]}}
                      </div>
                    </div>
                  </div>
                  <div v-show="!indexArr">
                    <div v-for="(item, index) in arr">
                      <div class="ml-12px radius-100 flex-none mr-3 mt-1 mb-3"
                           :style="'width: 8px; height: 8px; background-color: ' + colors[index] + ';float: left;'">
                      </div>
                      <div class="opacity-72 ml-6px whitespace-nowrap text-c13 color-titanic mb-3">
                        {{item[0]}}
                      </div>
                    </div>
                  </div>
                </template>
              </TextAccordion>
            </div>
          </div>
          <div v-else class="overflow-hidden"
               style="height: 223px; position: relative;margin-bottom: 52px;">
            <div width="100%" style="min-height: 238px;">
              <div
                class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light"
                style="width: 276px; height: 223px;margin: auto;">
                <svg width="476" height="223" xmlns="http://www.w3.org/2000/svg"
                     version="1.1" class="apexcharts-svg" transform="translate(0, 0)"
                     style="background: transparent;margin-top: 26px;">
                  <g class="apexcharts-annotations"></g>
                  <g class="apexcharts-inner apexcharts-graphical">
                    <defs></defs>
                  </g>
                  <text font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                        x="135" y="129.5" text-anchor="middle" dominant-baseline="auto"
                        font-size="16px" fill="RGBA(4,21,62,0.49)"
                        class="apexcharts-text "
                        style="font-family: 'Golos UI';, 'Trebuchet MS', Verdana, sans-serif; opacity: 1;">
                    Нет данных за выбранный период
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import TextAccordion from "../TextAccordion";
import PlaceApi from '@/api/PlaceApi';
import Loader from '@/components/Loader.vue';

const api = new PlaceApi();

export default {
  name: 'Problem',
  props: {
    items: {
      type: Object,
      default: {}
    },
  },
  components: {
    Loader,
    apexchart,
    TextAccordion
  },
  data() {
    return {
      indexArr: true,
      colors: ['#C7E6A8', '#BB9AF4', '#E14761', '#3D75E4', '#81ABEE', '#9954F2',
        '#5E0DAC', '#FF9E01', '#CD0174', '#01CC01', '#1341AB', '#FF7502', '#019999', '#CCF602'],
      arr: [],
      count: 0,
      label: [],
      uploadGraph: false,
      widthResize: 'width: 50%',
      notGraph: true,
      series: [],
      chartOptions: {}
    };
  },
  computed: {},
  methods: {
    editArr() {
      this.indexArr = !this.indexArr;
    },
    declOfNum(number, titles) {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    }
  },
  mounted() {
    let _this = this;
    let num = 0;
    for (var i in _this.items) {
      this.arr.push([""+i+" "+_this.declOfNum(i,['звезда','звезды','звёзд']),+_this.items[i]]);
      num++;
    }
    if(num === this.arr.length) {
      _this.uploadGraph = false;
    }
    this.arr.forEach(function (item) {
      _this.series.push(item[1]);
      _this.label.push(item[0]);

    });
    _this.count++;
    this.chartOptions = {
      chart: {
        type: 'pie',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      labels: _this.label,
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
      },
      title: {
        text: '',
        align: 'center'
      },
      responsive: [{
        breakpoint: 380,
        options: {
          legend: {
            position: 'top',
            offsetX: 0,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        align: 'right',
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        type: 'category',
        tickPlacement: 'between',
        labels: {
          offsetX: 4,
          rotate: -1,
          showDuplicates: false,
          hideOverlappingLabels: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400,
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      colors: ['#C7E6A8', '#BB9AF4', '#E14761', '#3D75E4', '#81ABEE'],
      yaxis: {
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400,
          },
        },
      },
    }
  },
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_date {
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
}
::v-deep .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0% 0% 0% 0% round 8px) !important;
}
::v-deep .apexcharts-pie-series path{
  filter: none !important;
  clip-path: inset(0% 0% 0% 0% round 8px) !important;
}
.legend {
  display: table;
  width: 200px;
  float: left;
}
.time {
  width: 50%;
  float: left;
}
.graf {
  width: 50%;
  float: left;
}
@media screen and (max-width: 1123px) {
  .time {
    width: 100%;
    float: left;
  }
  .graf {
    width: 60%;
  }
}
@media screen and (max-width: 900px) {
  .legend {
    width: 40%;
  }
  .graf {
    width: 60%;
    margin-left: calc(50% - 150px);
  }
}
@media screen and (max-width: 500px) {
  .legend {
    width: 250px;
  }
  .graf {
    width: 250px;
    margin-left: calc(50% - 125px);
  }
  .time {
    width: 100%;
    float: left;
  }
}
</style>
