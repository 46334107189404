<template>
  <div>
    <svg width="16" height="16" class="mr-3 inline-block mr-12px color-rocky" v-if="graf == 'resizeOff'"
         viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#FEvy68ff__clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.5 6.5V1.5C8.5 0.947715 8.94771 0.5 9.5 0.5C10.0523 0.5 10.5 0.947715 10.5 1.5V4.08579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L11.9142 5.5H14.5C15.0523 5.5 15.5 5.94772 15.5 6.5C15.5 7.05228 15.0523 7.5 14.5 7.5H9.5C8.94771 7.5 8.5 7.05228 8.5 6.5Z"
              fill="currentColor"></path>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.5 9.5V14.5C7.5 15.0523 7.05228 15.5 6.5 15.5C5.94771 15.5 5.5 15.0523 5.5 14.5V11.9142L1.70711 15.7071C1.31658 16.0976 0.683418 16.0976 0.292893 15.7071C-0.097631 15.3166 -0.097631 14.6834 0.292893 14.2929L4.08579 10.5H1.5C0.947715 10.5 0.5 10.0523 0.5 9.5C0.5 8.94771 0.947715 8.5 1.5 8.5H6.5C7.05228 8.5 7.5 8.94771 7.5 9.5Z"
              fill="currentColor"></path>
      </g>
      <defs>
        <clipPath id="FEvy68ff__clip0">
          <rect width="16" height="16" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>

    <svg width="16" height="16" class="mr-3 inline-block mr-12px color-rocky"  v-if="graf == 'resizeOn'"
         viewBox="0 0 16 16" fill="none">
      <path
        d="M16 6V1C16 0.447715 15.5523 0 15 0H10C9.44772 0 9 0.447715 9 1C9 1.55228 9.44771 2 10 2L12.5858 2L8.79289 5.79289C8.40237 6.18342 8.40237 6.81658 8.79289 7.20711C9.18342 7.59763 9.81658 7.59763 10.2071 7.20711L14 3.41421V6C14 6.55228 14.4477 7 15 7C15.5523 7 16 6.55228 16 6Z"
        fill="currentColor"></path>
      <path
        d="M0 10V15C0 15.5523 0.447715 16 1 16H6C6.55228 16 7 15.5523 7 15C7 14.4477 6.55229 14 6 14L3.41421 14L7.20711 10.2071C7.59763 9.81658 7.59763 9.18342 7.20711 8.79289C6.81658 8.40237 6.18342 8.40237 5.79289 8.79289L2 12.5858L2 10C2 9.44772 1.55229 9 1 9C0.447716 9 0 9.44772 0 10Z"
        fill="currentColor"></path>
    </svg>

    <svg width="16" height="16" class="mr-3 inline-block mr-12px color-rocky" v-if="graf == 'grafOff'"
         viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6 1C6.55228 1 7 1.44772 7 2V14C7 14.5523 6.55228 15 6 15C5.44772 15 5 14.5523 5 14V2C5 1.44772 5.44772 1 6 1ZM10 4.42857C10.5523 4.42857 11 4.87629 11 5.42857V14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14V5.42857C9 4.87629 9.44772 4.42857 10 4.42857ZM2 7C2.55228 7 3 7.44772 3 8V14C3 14.5523 2.55228 15 2 15C1.44772 15 1 14.5523 1 14V8C1 7.44772 1.44772 7 2 7Z"
            fill="currentColor"></path>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14 3C14.5523 3 15 3.44772 15 4V14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14V4C13 3.44772 13.4477 3 14 3Z"
            fill="currentColor"></path>
    </svg>

    <svg width="16" height="16" class="mr-3 inline-block mr-12px color-rocky"  v-if="graf == 'grafOn'"
         viewBox="0 0 16 16" fill="none">
      <path d="M1.5 8L3 5.5L7.5 7L10.5 2" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M3 13.5L5 10.5L9.5 12.5L13.5 6" stroke="currentColor"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconGraf",
  props: {
    graf: {
      type: String,
      default: ''
    },
  },
}
</script>

<style scoped>
</style>
