import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cleaningList: [],
    cleaningListId: [],
    types: null,
    objects: null,
    selectedObject: null,
    isObjectLoading: null,
    active: null,
    saveNew: null,
    comments: null,
    commentsSave: null,
    user: null,
    rating: null,
    score: null,
    userAdd: null,
    userCsv: null,
    isObjectCler: null
  },
  mutations: {
    setCleaningList(state, items) {
      if (Array.isArray(items)) {
        state.cleaningList = items;
      }
    },
    setCleaningListId(state, items) {
        state.cleaningListId = items;
    },
    // ===------
    setTypes(state, types) {
      state.types = types;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setSelectedObject(state, object) {
      state.selectedObject = object;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    },
    setObjectCler(state, status) {
      state.isObjectCler = status;
    },
    setActive(state, objects) {
      state.active = objects;
    },
    setSaveNew(state, types) {
      state.saveNew = types;
    },
    setComments(state, objects) {
      state.comments = objects;
    },
    setCommentSave(state, objects) {
      state.commentsSave = objects;
    },
    setUser(state, objects) {
      state.user = objects;
    },
    setUserRating(state, objects) {
      state.rating = objects;
    },
    setScore(state, objects) {
      state.score = objects;
    },
    setUserAdd(state, objects) {
      state.userAdd = objects;
    },
    setCsv(state, objects) {
      state.userCsv = objects;
    },

  },
  getters: {
    getTypes: () => {
      return state.objects.types;
    },
    getObjectById: state => id => {
      return state.objects.find(el => el.id === id);
    },
    getActive: () => {
      return state.objects;
    },
    getSaveNew: () => {
      return state.objects;
    },
    getComments: () => {
      return state.objects;
    },
    getCommentSave: () => {
      return state.objects;
    },
    getUser: () => {
      return state.objects;
    },
    getUserRating: () => {
      return state.objects;
    },
    getScore: () => {
      return state.objects;
    },
    getUserAdd: () => {
      return state.objects;
    },
    getCsv: () => {
      return state.objects;
    },
  },
  actions: {
    async getCleaning(ctx, data) {
      const res = await api.getCleaning(data);
      console.log('CLEANING', res);
      ctx.commit('setCleaningList', res.all);
    },
    async getCleaningId(ctx, data) {
      const res = await api.getCleaningId(data);
      console.log('CLEANING ID', res);

      ctx.commit('setCleaningListId', res.all);
      if(!res.all.length){
        ctx.commit('setObjectCler', true);
        console.log(111114)
      } else {
        ctx.commit('setObjectCler', false);
        console.log(43434334)
      }
    },
    // ===---====

    async selectObject(ctx, id) {
      if (!ctx.state.objects?.length) {
        const res = await api.getList({ readOnly: 1, type: 0, currentMainMenuNumber: 5 });
        ctx.commit('setObjects', res.all);
      }
      const selected = ctx.state.objects.find(el => el.id === id);
      ctx.commit('setSelectedObject', selected);
    },
    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList({ readOnly: 1, type: typeId, currentMainMenuNumber: 5 });
      ctx.commit('setObjects', res.all);
      ctx.commit('setObjectLoading', false);
    },
    async loadTypes(ctx) {
      const res = await api.getTypes();
      ctx.commit('setTypes', res.types);
    },
    async loadActivity(ctx, payload) {
      const res = await api.getActivity(payload);
      ctx.commit('setActive', res);

      return res;
    },
    async loadActivityId(ctx, payload) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getActivityId(payload);
      ctx.commit('setActive', res);
      ctx.commit('setObjectLoading', false);

      return res;
    },
    async loadSaveNew(ctx, payload) {

      console.log('sdfsdfsd', payload);
      const res = await api.setSaveNew(payload);
      ctx.commit('setSaveNew', res);

      return res;
    },
    async loadComments(ctx, payload) {
      const res = await api.getComments(payload);
      ctx.commit('setComments', res);

      return res;
    },
    async saveComment(ctx, payload) {
      const res = await api.getCommentSave(payload);
      ctx.commit('setCommentSave', res);

      return res;
    },
    async user(ctx) {
      const res = await api.getUser();
      ctx.commit('setUser', res);

      return res;
    },
    async userRating(ctx) {
      const res = await api.getUserRating();
      ctx.commit('setUserRating', res);

      return res;
    },
    async myScore(ctx) {
      const res = await api.getScore();
      ctx.commit('setScore', res);

      return res;
    },
    async getUserAdd(ctx,id) {
      const res = await api.getUserAdd(id);
      ctx.commit('setUser', res);

      return res;
    },
    async getCsv(ctx,id) {
      const res = await api.getCsv(id);
      ctx.commit('setCsv', res);

      return res;
    },
  },
  modules: {}
});
