<template>
  <div>
    <div class="w-full" :style="widthResize" style="float: left;">
      <div class="m-8px p-16px bg-white radius-16 border-arrival" style="display: flow-root;">
        <div class="flex justify-between">
          <div class="flex items-center">
            <div class="overflow-ellipsis webkit-box text-h20-24 color-titanic">
              Быстрые оценки сайту
            </div>
          </div>
        </div>
        <div class="rir-drag-upload-file__uploading" style="height: 277px;" v-if="uploadGraph">
          <Loader style="height: 223px;"/>
        </div>
        <div v-if="!uploadGraph">
          <div v-if="item && item.length > 0">
            <div class="mt-20px">
              <apexchart
                class="isSingleItem"
                :type="'bar'"
                :options="chartOptions"
                :series="series"
                height="223"></apexchart>
              <div class="legend">
                <div class="ml-12px radius-100 flex-none mr-3 mt-1"
                     style="width: 8px; height: 8px; background-color: #81ABEE;float: left;">
                </div>
                <div class="opacity-72 ml-6px whitespace-nowrap text-c13 color-titanic" style="float: left">
                  Количество
                </div>
              </div>
            </div>
          </div>
          <div v-else class="overflow-hidden"
               style="height: 223px; position: relative;margin-bottom: 52px;">
            <div width="100%" style="min-height: 238px;">
              <div
                class="apexcharts-canvas apexchartsiiyag009 apexcharts-theme-light"
                style="width: 276px; height: 223px;margin: auto;">
                <svg width="476" height="223" xmlns="http://www.w3.org/2000/svg"
                     version="1.1" class="apexcharts-svg" transform="translate(0, 0)"
                     style="background: transparent;margin-top: 26px;">
                  <g class="apexcharts-annotations"></g>
                  <g class="apexcharts-inner apexcharts-graphical">
                    <defs></defs>
                  </g>
                  <text font-family="Golos UI, Trebuchet MS, Verdana, sans-serif"
                        x="135" y="129.5" text-anchor="middle" dominant-baseline="auto"
                        font-size="16px" fill="RGBA(4,21,62,0.49)"
                        class="apexcharts-text "
                        style="font-family: 'Golos UI';, 'Trebuchet MS', Verdana, sans-serif; opacity: 1;">
                    Нет данных за выбранный период
                  </text>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import PlaceApi from '@/api/PlaceApi';
import Loader from '@/components/Loader.vue';

const api = new PlaceApi();

export default {
  name: 'Problem',
  props: {
    items: {
      type: Object,
      default: {}
    },
    date: {
      type: Date,
      default: null
    },
    dateEnd: {
      type: Date,
      default: null
    }
  },
  components: {
    Loader,
    apexchart,
  },
  data() {
    return {
      monthDate: [],
      uploadGraph: false,
      widthResize: 'width: 100%',
      notGraph: true,
      item: [],
      itemDel: [],
      daysItem: [],
      series: [],
      chartOptions: {},
    };
  },
  computed: {},
  mounted() {
    let _this = this;

    for (var i in _this.items) {
      if(_this.items[i]) {
        if(_this.items[i]) {
          _this.item.push(_this.items[i])
        } else {
          _this.item.push(0)
        }
        _this.daysItem.push(i);
      }
    }
    _this.series = [{
      name: 'Количество',
      data: _this.item
    }];
    this.chartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        //stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 0,
      },
      title: {
        text: '',
        align: 'center'
      },
      responsive: [{
        breakpoint: 380,
        options: {
          legend: {
            position: 'top',
            offsetX: 0,
            offsetY: 0
          }
        }
      }],
      xaxis: {
        align: 'right',
        categories: _this.daysItem,
        type: 'category',
        tickPlacement: 'between',
        labels: {
          offsetX: 3,
          rotate: -1,
          rotateAlways: true,
          showDuplicates: false,
          hideOverlappingLabels: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400,
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      yaxis: {
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Golos UI, Trebuchet MS, Verdana, sans-serif',
            fontWeight: 400,
          },
        },
      },
      colors: ['#81ABEE', '#8DC95E'],
    }
  },
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.cleaning_date {
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
}

::v-deep .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
  clip-path: inset(0% 0% 0% 0% round 8px) !important;
}
.legend {
  margin: 0 auto;
  width: 150px;
}
</style>
