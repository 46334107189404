<template>
  <div>
    <div class="w-full" :style="widthResize" style="float: left;">
      <div class="m-8px p-16px bg-white radius-16 border-arrival">
        <div class="flex" style="margin-bottom: 4px;">
          <div class="flex items-center">
            <div class="overflow-ellipsis webkit-box text-h20-24 color-titanic mb-5" style="width: 100%;">
              Тепловая карта обращений
            </div>
          </div>
        </div>
        <div>
          <r-select class="flex mb-4" style="max-width: 400px;" label="Тип обращения"
                      :value="isCheckSort"
                      :items="Objects"
                      @change="onChangeSort"/>
        </div>
        <div class="objects__map">
          <div :key="count" id="YMapsID" :class="['rir-map', { 'rir-map--fixed': !isCollapsed }]" ref="map">
            <div class="zoom">
              <r-button-simple
                style="display: block;"
                class="mb-4 amelie"
                :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
                fill="rocky"
                icon-size="20"
                @click="clickFull"
                type="light"
                size="larishae"
                title=""
              />
              <r-button-simple
                style="display: block;"
                type="light"
                size="larishae"
                icon="add"
                class="amelie"
                fill="rocky"
                icon-size="20"
                @click="onZoom(1)"
                title=""
              />
              <r-button-simple
                style="display: block;"
                type="light"
                size="larishae"
                class="mt-1 mb-4 amelie"
                icon="remove"
                fill="rocky"
                icon-size="20"
                @click="onZoom(-1)"
                title=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import IconGraf from "../IconGraf";
import DropDownButton from "../DropDownButton";
import AdditionalMenu from "../AdditionalMenu";
import {loadYmap} from 'vue-yandex-maps';
import PlaceApi from '@/api/PlaceApi';
import RirSelect from "@/components/DropdownSelect.vue";

const api = new PlaceApi();

export default {
  name: 'Problem',
  props: {
    items: {
      type: Object,
      default: {}
    },
  },
  components: {
    RirSelect,
    IconGraf,
    apexchart,
    DropDownButton,
    AdditionalMenu
  },
  data() {
    return {
      uploadGraph: false,
      widthResize: 'width: 100%',
      notGraph: true,
      mapZoom: 11,
      myMap: null,
      isCollapsed: true,
      countMap: 0,
      data: [],
      coords: [],
      count: 0,
      type: [],
      isCheckSort: -1
    };
  },
  computed: {
    Objects() {
      const _this = this;
      if (!this.items?.all?.length) return [];

      var objects = this.items.all;
      let info = [{
        id: -1,
        title: "Все"
      }];
      objects
        .map(el => {
          if (el?.typeIdx) {
            info.push({
              id: el.typeIdx,
              title: el.typeFull
            });
          }
        });
      return info;
    }
  },
  async mounted() {
    let v = this;
    const settings = {lang: 'ru_RU'};
    await loadYmap(settings);
    const yandexMapScript = document.createElement('SCRIPT');
    const link = `https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js`;
    yandexMapScript.setAttribute('src', link);
    document.head.appendChild(yandexMapScript);
    yandexMapScript.onload = () => {
      api.getCoors().then(function (item) {
        if(item?.statistics?.coords) {
          v.coords = item.statistics.coords;

          v.data = v.coords;
          v.map(v.data);

        }
      });
    };
  },
  methods: {
    onChangeSort(sort) {
      this.isCheckSort = sort;
      let v = this;
      let item = sort == -1 ? null : sort;
      api.getCoors(item).then(function (items) {
        if(items?.statistics?.coords) {
          v.coords = items.statistics.coords;
        } else {
          v.coords = [];
        }

        v.data = v.coords;
        v.map(v.data);

      });
    },
    map() {
      let v = this;

      window.ymaps.ready(function () {
        if(v.myMap) {
          v.myMap.destroy();
        }
        v.myMap = new window.ymaps.Map("YMapsID", {
          center: v.$cityCenter,
          zoom: v.mapZoom,
          controls: []

        }, {
          searchControlProvider: 'yandex#search'
        });
        const clusterer = new ymaps.Clusterer({
          clusterIcons: [
            {
              href: process.env.VUE_APP_MAP_CLUSTER_ICON,
              size: [64, 64],
              offset: [-32, -32]
            },
          ],
        });
        const getPointOptions = function () {
          return {
            preset: 'islands#violetIcon'
          };
        };
        window.ymaps.modules.require(['Heatmap'], function (Heatmap) {

          var heatmap = new Heatmap(v.data);
          heatmap.options.set('gradient', {
            0.1: 'rgba(128, 255, 0, 0.7)',
            0.3: 'rgba(162, 36, 25, 1)',
            0.2: 'rgba(255, 255, 0, 0.8)',
          });
          heatmap.options.set('radius', 10);
          heatmap.setMap(v.myMap);
        });
      });
     // this.count++;
    },
    onZoom(val) {
      const v = this
      const newZoom = v.mapZoom + val;
      v.mapZoom = newZoom;
      v.myMap.setZoom(v.mapZoom);
    },
    clickFull() {
      this.isCollapsed = !this.isCollapsed;
      //this.data = [[54.941277, 43.312388],[54.942277, 43.312388]];
     // this.map();
      this.$nextTick(() => {
        this.myMap.container.fitToViewport();
      });
      },
  }
};
</script>

<style lang="scss" scoped>
.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }

    a {
      border-radius: 16px;

      &:hover {
        box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
      }
    }
  }

  &__map {
    height: 100%;
    border-radius: 24px;
  }
}

.zoom {
  position: absolute;
  z-index: 10;
  float: right;
  right: 16px;
  margin-top: 16px;
}

.rir-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);

    > * {
      background-color: #fff;
    }
  }

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__legend {
    padding: 8px 12px;
    background: var(--rir-amelie);
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 4px;
  }
}

.singleItem {
  :global(.apexcharts-xaxis) {
    transform: translate(calc(50% - 18px), 0);
  }
}

.objects {
  &__filter {
    margin-top: 24px;
    display: flex;
  }

  &__map {
    height: 300px;
    border-radius: 24px;
  }
}
.cleaning_date {
  ::v-deep .rir-input {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    max-height: 0px !important;

    &__body {
      padding: 0;
      flex: none;
      margin-left: 6px;
    }

    &__input {
      height: auto;
    }

    &__label {
      transform: translateY(1px);
      color: #3D75E4 !important;
      position: relative;
      left: unset;
      top: unset;
      font-size: 16px;
    }

    input {
      display: none;
    }

    &__after {
      position: relative;
      margin-left: 0;

      i {
        background-color: var(--rir-rocky) !important;
        opacity: 1;
        display: none !important;
      }

      a:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 110px;
        left: 100%;
        z-index: 9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .checked__class {
    display: inline-flex;
    margin-top: 1px !important;
  }
}
</style>
